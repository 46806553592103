import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, Form, Icon, Input, Modal } from 'semantic-ui-react';
import { shuffle } from 'lodash';
import Autosuggest from 'react-autosuggest';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Link from './Link';

const N_SUGGESTIONS = 8;

const StyledSearchForm = styled(Form)`
  &&& {
    font-size: 1.1rem;
    margin: 0 auto;

    input[type='text'] {
      border-radius: 1.3rem;
    }
  }
`;
function pinkCodeLink(code) {
  return (
    <Link to={`/search?query=${code.replace('+', '%2B').replace('"', '%22')}`}>
      <code style={{ color: 'lightcoral' }}>{code}</code>
    </Link>
  );
}

const StyledFakeButton = styled.a`
  && {
    cursor: pointer;
    text-decoration: underline;
    color: #2b8182 !important;
  }
  &&:hover {
    color: #1b4b4c !important;
  }
`;

export function SearchSyntaxModal() {
  return (
    <Modal
      trigger={<StyledFakeButton>Search Syntax</StyledFakeButton>}
      closeIcon
    >
      <Modal.Header>Matscholar Search Syntax</Modal.Header>
      <Modal.Content>
        <ul>
          <li>
            <div> Use quotes to search for a specific multi-word phrase.</div>
            <div className="center-block">
              {' '}
              e.g. {pinkCodeLink('"gold nanoparticles"')}
            </div>
          </li>
          <li>
            <div>
              {' '}
              Use <code>+query_term</code> to specify that the result must
              include the term and -query_term for must not.
            </div>
            <div className="center-block">
              {' '}
              e.g. {pinkCodeLink('+nanoparticle -nanorod')}
            </div>
          </li>
          <li>
            <div>
              {' '}
              Use {pinkCodeLink('()')} to specify OR, matches any of the terms
              inside.
            </div>
            <div> e.g. {pinkCodeLink('gold +(nanoparticle nanorod)')}</div>
          </li>
          <li>
            <div> To search specific fields use fieldname:query_term.</div>
            <div className="center-block">
              {' '}
              e.g. {pinkCodeLink('title:"Computational" tag:Inorganic')}
            </div>
          </li>
          <li>
            <div>
              {' '}
              To search by <a href={'/nlp'}>NLP-extracted entities</a> use
              entity_name:query_term.
              <br />
              Valid entity names are:
              <p>
                materials, applications, properties, phase_labels,
                synthesis_methods, characterization_methods, descriptors
              </p>
            </div>
            <div className="center-block">
              {' '}
              e.g.{' '}
              {pinkCodeLink('materials:ITO applications:transparent conductor')}
            </div>
          </li>
        </ul>
      </Modal.Content>
    </Modal>
  );
}

const sampleQueries = [
  '+TiO2 +transparent conductivity',
  'band gap of +"PZT"',
  'synthesis of gold nanoparticles',
  '+title:"zeolite" +abstract:"ab-initio"'
];

let input_obj = {};
function SearchForm({ onSearch, query = '', show_button = false }) {
  const [currentQuery, setCurrentQuery] = useState(query);
  const [suggestions, setSuggestions] = useState([]);
  const trigger = useRef(new Subject());
  const isMounted = useRef(false);
  const inputRef = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  useEffect(() => {
    const subscription = trigger.current
      .pipe(
        switchMap(v =>
          fetch(
            `/api/suggest/?n=${N_SUGGESTIONS}&q=${v.replace('+"', '@@')}`
          ).then(r => r.json())
        )
      )
      .subscribe(({ l }) => {
        if (!l) {
          console.warn('response is null');
        } else {
          isMounted.current && setSuggestions(l); // component can be unmounted when the request is done
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (query !== currentQuery) setCurrentQuery(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleSearch = () => {
    onSearch({ query: currentQuery });
  };

  const onChange = (e, { newValue }) => {
    setCurrentQuery(newValue);
  };

  const inputProps = {
    value: currentQuery,
    onChange
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    trigger.current.next(value);
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  // I've intended to use this, but it looses the focus
  const CustomInput = React.forwardRef((props, _ref) => {
    return (
      <Input
        fluid
        icon={<Icon name="search" link onClick={handleSearch} />}
        placeholder={'Search...'}
        autoFocus={true}
        className="input"
        onChange={(e, { value }) => setCurrentQuery(value)}
        ref={ref => {
          input_obj.input = ref; // this will break if you have multiple components on the screen
        }}
        value={currentQuery}
      />
    );
  });

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    onSearch({ query: suggestionValue });
  };

  return (
    <>
      <StyledSearchForm onSubmit={handleSearch}>
        <Autosuggest
          suggestions={suggestions}
          multiSection={false}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          renderSectionTitle={() => {}}
          getSectionItems={() => {}}
          getSuggestionValue={s => s}
          inputProps={inputProps}
          ref={inputRef}
          renderSuggestion={renderSuggestion}
          renderInputComponent={props => (
            <input {...props} className="input" placeholder="Search..." />
          )}
        />
      </StyledSearchForm>
      {show_button ? (
        <>
          <Button
            onClick={() => {
              setCurrentQuery(shuffle(sampleQueries)[0]);
              inputRef.current.input.focus();
            }}
          >
            Example
          </Button>
          &nbsp;
          {SearchSyntaxModal()}
        </>
      ) : (
        ''
      )}
    </>
  );
}

const renderSuggestion = suggestion => <span>{suggestion}</span>;

export default SearchForm;
