import { navigate } from '@reach/router';

const relatedToRegex = /(?:^|\s)(\+?related_to:[0-9]+)(?:$|\s)/;

// all(group(source_display) order(-count()) each(output(count())))
// all(group(document_type) order(-count()) each(output(count())))
// all(group(is_covid19) order(-count()) each(output(count())))
// all(group(tags) order(-count()) each(output(count())))
// all(group(is_preprint) order(-count()) each(output(count())))

const generalSelect = `all(group(time.year(timestamp)) max(10) order(-max(time.year(timestamp))) each(output(count())) as(year))`
  .split('\n')
  .map(s => s.trim())
  .join('');

//     all(group(time.year(timestamp)) max(10) order(-max(time.year(timestamp))) each(output(count())) as(year))

const entitySelect = `all(where(true) all( max(100000) all(
     all(group(materials) order(-count()) max(100) precision(200) each(output(count())))
     all(group(properties) order(-count()) max(10) precision(30) each(output(count())))
     all(group(applications) order(-count()) max(10) precision(30) each(output(count())))
     all(group(characterization_methods) order(-count()) max(10) precision(30) each(output(count())))
     all(group(synthesis_methods) order(-count()) max(10) precision(30) each(output(count())))
     all(group(phase_labels) order(-count()) max(10) precision(30) each(output(count())))
     all(group(descriptors) order(-count()) max(10) precision(30) each(output(count())))
   )))`
  .split('\n')
  .map(s => s.trim())
  .join('');

// Combines an array of possible values for a given field into an OR expression that is ANDed with other filters
const orCombiner = (field, array, range = false) =>
  array.length
    ? '+(' +
      array
        .map(s => (range ? `${field}:[${s}]` : `${field}:"${s}"`))
        .join(' ') +
      ')'
    : null;

function timestampRange(days) {
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  const now = date / 1000;
  if (days > 0) {
    date.setDate(date.getDate() - days);
    date.setHours(0, 0, 0, 0);
    const then = date / 1000;
    return now + ';' + then;
  } else {
    const then = 0;
    return now + ';' + then;
  }
}

const generateApiQueryParams = () => {
  const {
    tags,
    year,
    materials,
    properties,
    applications,
    synthesis_methods,
    phase_labels,
    descriptors,
    characterization_methods,
    date_range
  } = getSearchState();
  // const timestampRanges = year
  //   .map(y => parseInt(y))
  //   .map(
  //     y =>
  //       timestampStartOfYearUtc(y) + ';' + (timestampStartOfYearUtc(y + 1) - 1)
  //   );
  const timestampRanges = [timestampRange(parseInt(date_range))];

  // orCombiner('is_covid19', is_covid19),
  // orCombiner('is_preprint', is_preprint),
  // orCombiner('document_type', document_type),
  // orCombiner('tags', tags ? tags : []),
  // orCombiner('source_display', source_display),

  const filter = [
    orCombiner('year', year),
    orCombiner('timestamp', timestampRanges, true),
    orCombiner('materials', materials),
    orCombiner('properties', properties),
    orCombiner('applications', applications),
    orCombiner('characterization_methods', characterization_methods),
    orCombiner('synthesis_methods', synthesis_methods),
    orCombiner('phase_labels', phase_labels),
    orCombiner('descriptors', descriptors)
  ]
    .filter(s => s)
    .join(' ');
  // console.log(window.location.search);
  const query = new URLSearchParams(window.location.search);
  const ranking = query.get('ranking');
  const fieldset = query.get('fieldset') || 'all';
  // Remove query parameters used in the UI, these are either sent to backend under a different name
  // or as part of an expression (filters)
  [
    'year',
    'ranking',
    'fieldset',
    'materials',
    'properties',
    'applications',
    'descriptors',
    'characterization_methods',
    'synthesis_methods',
    'phase_labels'
  ].forEach(q => query.delete(q));
  if (filter) query.set('filter', filter);
  if (ranking) query.set('ranking.profile', ranking);
  if (fieldset) query.set('model.defaultIndex', fieldset);
  query.set('wand.hits', '250');
  if (query.get('query') === null || query.get('query').trim().length === 0) {
    query.set('select', generalSelect);
    query.set(
      'yql',
      `select * from sources * where timestamp > 0 AND userQuery();`
    );
  } else {
    query.set('select', entitySelect);
    query.set(
      'yql',
      `select * from sources * where timestamp > 0 AND userQuery();`
    );
  }
  // console.log(query.toString());
  return query;
};

const onSearch = params => {
  const urlParams = new URLSearchParams(window.location.search);

  for (let [key, value] of Object.entries(params)) {
    urlParams.delete(key);
    if (Array.isArray(value)) value.forEach(v => urlParams.append(key, v));
    else if (value) urlParams.set(key, value);
  }
  // Offset must be reset whenever result set changes, which we assume may be
  // every time the URL changes due to other interactions than with pagination.
  if (!params.hasOwnProperty('offset')) urlParams.delete('offset');

  // No query or filters specified
  if (urlParams.entries().next().done) return;
  navigate('/materials-search?' + urlParams);
};

const getRelatedId = urlParams => {
  const query = urlParams.get('query');
  if (!query) return null;
  const match = query.match(relatedToRegex);
  if (!match) return null;
  return match[1].split(':')[1];
};

const getSearchState = () => {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    query: urlParams.get('query') || '',
    journal: urlParams.getAll('journal'),
    year: urlParams.getAll('year'),
    author: urlParams.getAll('author'),
    ranking: urlParams.get('ranking'),
    fieldset: urlParams.get('fieldset') || 'all',
    relatedId: getRelatedId(urlParams),
    date_range: urlParams.getAll('date_range'),
    materials: urlParams.getAll('materials'),
    properties: urlParams.getAll('properties'),
    applications: urlParams.getAll('applications'),
    synthesis_methods: urlParams.getAll('synthesis_methods'),
    phase_labels: urlParams.getAll('phase_labels'),
    characterization_methods: urlParams.getAll('characterization_methods'),
    descriptors: urlParams.getAll('descriptors')
  };
};

export { generateApiQueryParams, getSearchState, onSearch, relatedToRegex };
