import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { Container, Segment } from 'semantic-ui-react';
import Link from './Link';

const FooterBox = styled(Box)`
  &&& {
    width: 100%;
    margin: auto 0 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .ui {
    margin: 0;
    border: 0;
    box-shadow: none;
  }

  .ui.segment {
    padding: 2em 0em;
  }

  .ui.segment.sides {
    width: 200px;
    padding: 0;
  }

  .ui.image {
    width: 100px;
    margin: auto;
  }
`;

function Footer({ page = null }) {
  return page === 'main' ? (
    ''
  ) : (
    <FooterBox page={page}>
      <Container>
        <Segment.Group size="small">
          <Segment basic textAlign="center">
            <span>Copyright 2022 Lawrence Berkeley National Laboratory</span>
            <span>&nbsp;|&nbsp;</span>
            <Link to="/privacy">Privacy Policy</Link>
            <br />
          </Segment>
          {page !== 'main' && <Segment className="sides" basic></Segment>}
        </Segment.Group>
      </Container>
    </FooterBox>
  );
}

export default Footer;
