import React from 'react';
import styled from 'styled-components';
import { Card } from 'semantic-ui-react';
import Link from 'App/shared/components/Link';
import ReadMore from './ReadMore';
import AuthorsJournalDate from './ResultComponents/AuthorsJournalDate';
import { authorFormatter } from '../shared/utils/formatter';
import {
  MaterialsKeywordsSection,
  ApplicationsKeywordsSection
} from './ResultComponents/Keywords';
import { CardCategory } from './ResultComponents/CardCategory';
import { docTypeToColor } from '../Theme';

const StyledCard = styled(Card)`
  && {
    margin-top: 1.5em !important;
    margin-bottom: 1em !important;

    a {
      color: #005a8e;
    }

    a:hover {
      color: #1a7db6;
    }

    a.float-right {
      float: right;
      margin-right: 0.25em;
      color: #de0008;
    }

    a.title:hover,
    a.title:focus {
      color: #205bbc !important;
    }

    a.review-link {
      color: #6c00ba;
      text-decoration: underline;
    }

    .human-summary-submission a {
      color: #de0008;
      text-decoration: underline;
    }

    .content {
      padding: 0.3em 0.1em;
      border: 0;
      width: 100%;
    }

    .content > div {
      margin: 3px 0;
    }

    .category .label:not(:first-child) {
      padding: 0.25em 0.5em 0.25em 0.5em;
    }

    .ribbon {
      position: absolute;
      left: -16px;
      top: -15px;
    }
  }

  &.ui.card.red {
    box-shadow: 0 0 0 1px #d4d4d5, -3px 0 0 0 #db2828, 0 1px 3px 0 #d4d4d5;
  }

  &.ui.card.blue {
    box-shadow: 0 0 0 1px #d4d4d5, -3px 0 0 0 #2185d0, 0 1px 3px 0 #d4d4d5;
  }

  &.ui.card.green {
    box-shadow: 0 0 0 1px #d4d4d5, -3px 0 0 0 #21ba45, 0 1px 3px 0 #d4d4d5;
  }

  &.ui.card.purple {
    box-shadow: 0 0 0 1px #d4d4d5, -3px 0 0 0 #6c00ba, 0 1px 3px 0 #d4d4d5;
  }

  &.card {
    width: 100%;
    margin: 0.5em;
    padding: 1em 2em;
  }

  .header {
    padding-bottom: 5px;
  }

  .title {
    font-weight: bold;
  }

  .subtitle {
    font-weight: normal;
  }

  &.card .meta {
    font-size: 0.9em;
    padding: 0 0.5em;
    color: rgba(48, 48, 48, 0.5);

    a.doi {
      float: right;
    }
  }

  .meta:after {
    content: '';
    display: table;
    clear: both;
  }

  .larger {
    font-size: 1.25rem !important;
  }
`;

const highlightRegex = /<hi>(.*?)<\/hi>/g;
const formatText = text => {
  if (!text) return null;
  return (
    text
      .replace(/<sep \/>/g, '…')
      .split(highlightRegex)
      // The highlighted sections will be every other element starting at 1, wrap these in bold while keep others as strings
      .map((r, i) => (i % 2 === 0 ? r : <b key={i}>{r}</b>))
  );
};

// Link which has a dummy target which is ignored, only triggers the onClick callback
const FunctionLink = ({ onClick, ...props }) => (
  <a
    href="."
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
  >
    {props.children}
  </a>
);

// function SourceAndCitations({ source, citations_count_total }) {
//   const showCitations = citations_count_total > 0;
//   if (!source && !showCitations) return null;
//   return (
//     <div>
//       {source && (
//         <>
//           <b>Source: </b>
//           {source}
//         </>
//       )}
//       {source && showCitations && <>, </>}
//       {showCitations && (
//         <>
//           <b>Citations: </b>
//           {citations_count_total}
//         </>
//       )}
//     </div>
//   );
// }

function authorsList(authors) {
  if (!authors) return '';
  return authors.map(authorFormatter).join(', ');
}

function LoadingFakeCard() {
  return (
    <StyledCard>
      <div className={'ui fluid placeholder'}>
        <Card.Content className={'paragraph'} style={{ padding: 0 }}>
          <div key={1} className={'line'} style={{ marginBottom: '0.5em' }}>
            &nbsp;
          </div>
          <div key={2} className={'line'} style={{ marginBottom: '0.5em' }}>
            &nbsp;
          </div>
          <div key={3} className={'line'} style={{ marginBottom: '0.5em' }}>
            &nbsp;
          </div>
          <div key={4} className={'line'} style={{ marginBottom: '0.5em' }}>
            &nbsp;
          </div>
          <div key={5} className={'line'} style={{ marginBottom: '0.5em' }}>
            &nbsp;
          </div>
          <div key={6} className={'line'} style={{ marginBottom: '0.5em' }}>
            &nbsp;
          </div>
        </Card.Content>
      </div>
    </StyledCard>
  );
}

function ResultCard({
  fields: {
    id,
    title,
    timestamp,
    journal,
    doi,
    abstract,
    authors,
    link,
    materials,
    applications
  },
  onSearchSimilar,
  onFilterCategory
}) {
  const content = formatText(abstract);
  // const body = formatText(body_text);
  title = title || doi || link || id;
  const highlightedTitle = formatText(title);
  materials = materials ? materials : [];
  applications = applications ? applications : [];
  let tags = ['Inorganic'];
  let document_type = 'paper';
  const rr_logged_in =
    document.cookie
      .split(';')
      .map(i => i.replace(/^\s+|\s+$/, '').replace(/=.*$/, ''))
      .filter(i => i === 'rr_access_token').length > 0;

  return (
    <StyledCard className={docTypeToColor[document_type]}>
      <Card.Header>
        <Link className="title larger-txt" to={`/article/${id}`}>
          {highlightedTitle}
        </Link>
        {AuthorsJournalDate(
          authorsList(authors),
          journal,
          timestamp,
          true,
          true,
          true,
          100
        )}
      </Card.Header>

      <Card.Content>
        {content && (
          <div>
            <ReadMore long={content} />
          </div>
        )}
        <MaterialsKeywordsSection materials={materials} />
        <ApplicationsKeywordsSection applications={applications} />
        {/*<CardCategory tags={tags} onFilterCategory={onFilterCategory} />*/}
        {/*{onSearchSimilar && (*/}
        {/*  <FunctionLink onClick={onSearchSimilar}>*/}
        {/*    Search within related articles*/}
        {/*  </FunctionLink>*/}
        {/*)}*/}
      </Card.Content>
    </StyledCard>
  );
}

export { ResultCard, LoadingFakeCard };
