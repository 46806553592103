import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Button, Card, Icon, Loader } from 'semantic-ui-react';
import {ResultCard} from 'App/Search/ResultCard';
import { Box } from 'rebass';
import NavMenu from 'App/shared/components/NavMenu';
import Footer from 'App/shared/components/Footer';
import { random } from 'lodash';


// Information Extractor app lets the user choose 3 randomly chosen
// papers from the database and then extract information from them.

// It has 3 abstract cards at the top, each can be clicked on
// and when the user clicks on it, the extracted information is 
// displayed in the bottom half of the screen.

// There is a button below the three abstracts that says "extract information"
// and when the user clicks on it, the information is extracted from the 3 papers
// Next to that button is another button that says "get new papers"

const ARTICLES = require('App/shared/run_0.json');

function getEntry() {
    // Get a random entry from the ARTICLES array
    var entry = ARTICLES[Math.floor(Math.random() * ARTICLES.length)];
    const cleaned_prompt = entry['prompt'].replace("\n\n###\n\n", "")
    var title, abstract;
    if (cleaned_prompt.includes("\n")) {
        [title, ...abstract] = cleaned_prompt.split('\n');
    }
    else {
        title = "";
        abstract = cleaned_prompt;
    }
    console.log(abstract)
    var extracted;
    try {
        extracted = JSON.parse(entry['gpt3_completion'].replace("\n\nEND\n\n", ""))
    } catch (error) {
        extracted = []
    }
    entry = {"title": title, "abstract": abstract, "extracted": extracted};
    return entry;
}

const OptionsContainer = styled(Container)`
  &&& {
    font-size: 1rem;
    width: 100%;
    margin: 0.5em auto 0;
    padding: 0 0;
    // display: flex;
    

    #options {
      margin-top: 0.5em; // Must match #sidebar top padding
    }
  }
`;

class MyComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        entry: getEntry(),
        extracted: "",
        show_extraction: false,
        loading: false,
      };
    }
  
    handleExtract = (event) => {
        setTimeout(() => {
            this.setState({ extracted: this.state.entry.extracted });
            this.setState({ show_extraction: true})
        }, random(200, 500));
    }
  
    handleRefresh = () => {
      // Use the getEntry function to get a random entry from the ARTICLES array 
        // and set the state to the new entry
        this.setState({ entry: getEntry() });
        this.setState({show_extraction: false})
        this.setState({ loading: false });
    }
  
    render() {
      return (
        <div>
            <h3>Materials data extraction demo.</h3>
            <p>All extractions are exactly as output by GPT-3 model and entries were not seen during training.</p>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', width:'100%'}}>
                <Card fluid>
                <Card.Content>
                    <Card.Header>{this.state.entry.title}</Card.Header>
                    <Card.Description>
                        {this.state.entry.abstract}
                    </Card.Description>
                </Card.Content>
                </Card>
                <Button 
                floated='right' 
                primary 
                inline 
                compact 
                style={{ height: '4em', marginLeft: '1em'}}
                onClick={this.handleRefresh}>
                    <span style={{ padding: 0 }}>
                        <Icon name="refresh"/>
                    </span>
                </Button>
                </div>
            </div>
            <Button color="green" onClick={this.handleExtract} disabled={this.state.loading}> {!this.state.loading ? "Extract" : "Loading"} </Button>
            <Card.Group style={{paddingTop:"2em"}}>
            {this.state.entry.extracted.length ? this.state.entry.extracted.map((item) => {
                if (this.state.show_extraction) {
                    return (
                        <Card>
                        <Card.Content>
                            <Card.Description>
                                <pre>{JSON.stringify(item, ["formula", "name", "acronym", "structure_or_phase", "description", "applications"], 2)}</pre>
                            </Card.Description>
                        </Card.Content>
                        </Card>
                    )
                }
            }) : this.state.show_extraction ? <Card>
            <Card.Content>
                <Card.Description>
                    <pre>[]</pre>
                </Card.Description>
            </Card.Content>
            </Card> : null}
            </Card.Group>    
        </div>
      );
    }
  }

// function OptionCards() {
//     return (
//         <div display = "inline">
//             <OptionCards />
//             <Button color="blue" size="large" fluid> New Abstract</Button>
//         </div>
//     )
// }

function InformationExtractor() {
  
    return (
      <React.Fragment>
        <Box width={1}>
          <Box width={1}>
            <NavMenu logo="show" />
          </Box>
          <OptionsContainer id="options">
            <MyComponent/>  
          </OptionsContainer>
          <Footer page={'search'} />
        </Box>
      </React.Fragment>
    );
  }
  
  export default InformationExtractor;