const docTypeToColor = {
  paper: 'red',
  clinical_trial: 'green',
  patent: 'blue',
  dataset: 'purple'
};

const tagToColor = {
  Inorganic: 'orange',
  Polymer: 'teal'

  // Mechanism: 'yellow',
  // Treatment: 'pink',
  //
  // Prevention: 'purple',
  // Epidemic_Forecasting: 'brown',
  // Transmission: 'black'
};

const entityToColor = {
  Material: 'orange',
  Property: 'teal',
  Application: 'brown',
  Characterization_Method: 'green'

  // Mechanism: 'yellow',
  // Treatment: 'pink',
  //
  // Prevention: 'purple',
  // Epidemic_Forecasting: 'brown',
  // Transmission: 'black'
};

export { docTypeToColor, tagToColor };
