import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';
import Footer from 'App/shared/components/Footer';
import NavMenu from 'App/shared/components/NavMenu';
import styled from 'styled-components';
import MemberCard from './MemberBox';
import john_pic from './pics/john.jpg';
import gerd_pic from './pics/GerbrandCeder.jpg';
import kristin_pic from './pics/persson2.jpg';
import sanghoon_pic from './pics/sanghoon.JPG';
import alex_pic from './pics/team_alexD.jpg';
import anubhav_pic from './pics/team_anubhavJ.jpg';
import nick_pic from './pics/nick.jpg';
import { Box } from 'rebass';

const ContentGrid = styled(Grid)`
  &&& {
    min-height: calc(100vh - 130px);
    color: #4e4e4e;
    font-size: 1.1rem;
    margin-top: 0;

    .column {
      padding: 0;
    }

    h1 {
      font-size: 3.5rem;
      font-weight: 300;
    }

    .title {
      margin-bottom: 0;
      margin-left: 10px;
      margin-right: 10px;
    }

    .subtitle {
      margin-top: 0;
      margin-left: 10px;
      margin-right: 10px;
    }

    h4 {
      font-size: 1.1rem;
      margin: 3rem 0 0;
    }

    & .ui.list {
      margin: 0.5rem 0;
    }

    .ui.form {
      max-width: 800px;
      padding: 0 2rem;
    }

    a {
      color: #2b8182 !important;
      font-weight: 600;
      font-weight: normal;
    }

    a:hover {
      color: #1b4b4c !important;
    }

    .ui.card,
    .ui.card:first-child,
    .ui.card:last-child {
      margin: 0.5em;
    }
  }
`;

export default function About() {
  return (
    <Box width={1}>
      <Box width={1}>
        <NavMenu logo="show" />
      </Box>
      <ContentGrid>
        <Container>
          <Header as="h1" style={{ marginTop: '10px' }}>
            About Matscholar
          </Header>
          <p>
            This website uses natural language processing (NLP) to power search
            on a set of research papers related to materials science. It was
            created as part of a research effort led by the{' '}
            <a href="https://hackingmaterials.lbl.gov">Hacking Materials</a>,{' '}
            <a href="https://perssongroup.lbl.gov">Persson</a>, and{' '}
            <a href="https://ceder.berkeley.edu">Ceder</a> research groups at{' '}
            <a
              href="https://www.lbl.gov"
              target="_blank"
              rel="noopener noreferrer"
            >
              {'Lawrence Berkeley National Laboratory'}
            </a>
            .
          </p>
          <p>
            Matscholar is funded by the{' '}
            <a href="https://www.tri.global/our-work/material-design/">
              Accelerated Materials Discovery and Design
            </a>{' '}
            division of the Toyota Research Institute.
          </p>
          <p>
            If you use Matscholar in your research, please cite{' '}
            <a href="https://www.nature.com/articles/s41586-019-1335-8">
              Mat2vec
            </a>{' '}
            and{' '}
            <a href="https://pubs.acs.org/doi/abs/10.1021/acs.jcim.9b00470">
              Materials NER.
            </a>
          </p>

          <Header as="h3" style={{ marginTop: '10px' }}>
            Our team
          </Header>
          <Grid className={'center aligned'}>
            <MemberCard
              name={'Anubhav Jain'}
              intro={
                'Anubhav Jain is a Staff Scientist/Chemist focusing on new materials discovery' +
                'using high-throughput computations. He received his B.E. in Applied & Engineering' +
                ' Physics from Cornell University and his PhD from the Massachusetts ' +
                'Institute of Technology under the supervision of Gerbrand Ceder. He is a ' +
                'recipient of the DOE Computational Science Graduate Fellowship and the Luis ' +
                'W. Alvarez Postdoctoral Fellowship, and a DOE Early Career Award.'
              }
              pic={anubhav_pic}
              link={'https://hackingmaterials.lbl.gov/index.html#ABOUTUS'}
            />
            <MemberCard
              name={'Gerbrand Ceder'}
              intro={
                'Gerbrand Ceder is The Daniel M. Tellep Distinguished Professor in Engineering ' +
                'in the Department of Materials Science and Engineering at UC Berkeley. His research ' +
                'interests lie in computational and experimental materials design for clean energy ' +
                'technology, Materials Genome approaches to materials design and synthesis, and machine ' +
                'learning and NLP approaches to knowledge extraction.'
              }
              pic={gerd_pic}
              link={'https://ceder.berkeley.edu/'}
            />
            <MemberCard
              name={'Kristin Persson'}
              intro={
                'Kristin Persson is a Professor at the University of California, Berkeley and a Senior ' +
                'Faculty Scientist at Lawrence Berkeley National Laboratory.  She is the Director and ' +
                'co-founder of the Materials Project (www.materialsproject.org).'
              }
              pic={kristin_pic}
              link={'https://perssongroup.lbl.gov/'}
            />
            <MemberCard
              name={'John Dagdelen'}
              intro={
                'John is a PhD Student in the Persson Group at UC Berkeley and Lawrence Berkeley National ' +
                'Lab. His research sits at the intersection of materials science, artificial intelligence, ' +
                'and high-performance computing.'
              }
              pic={john_pic}
              link={'https://jdagdelen.github.io'}
            />
            {/*<MemberCard*/}
            {/*  name={'Amalie Trewartha'}*/}
            {/*  intro={*/}
            {/*    "Amalie is a postdoc in Gerbrand Ceder's group at Lawrence Berkeley National Lab. She began " +*/}
            {/*    'her career as a nuclear physicist, before moving into materials science in 2019, with a focus ' +*/}
            {/*    'on machine learning. Her research interests include the application of NLP techniques to ' +*/}
            {/*    'scientific literature, and building thermodynamically-motivated ML models for materials ' +*/}
            {/*    'property prediction.'*/}
            {/*  }*/}
            {/*  pic={amalie_pic}*/}
            {/*  link={'https://github.com/amaliet'}*/}
            {/*/>*/}
            <MemberCard
              name={'Sanghoon Lee'}
              intro={
                'Sanghoon is a 2nd Year PhD student in the Hacking Materials Group at LBNL.'
              }
              pic={sanghoon_pic}
              link={'https://github.com/slee-lab'}
            />
            <MemberCard
              name={'Alex Dunn'}
              intro={
                'Alex is a 4th Year PhD student in the Hacking Materials Group at LBNL.'
              }
              pic={alex_pic}
              link={'https://github.com/ardunn'}
            />
            <MemberCard
              name={'Nick Walker'}
              intro={
                'Nicholas Walker is a postdoctoral fellow at Berkeley Lab. He works in the' +
                ' Hacking Materials Group on NLP for creating structured data from unstructured text. ' +
                'His interests include physics, materials, and high performance computing.'
              }
              pic={nick_pic}
              link={'https://github.com/walkernr'}
            />
          </Grid>
        </Container>
        <Footer page={'about'} />
      </ContentGrid>
    </Box>
  );
}
